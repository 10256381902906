import * as React from "react";
import styled from "styled-components";

const H1 = styled.h1`
  font-size: 36pt;
  font-weight: 400;
  @media (max-width: 600px) {
    font-size: 24px;
  }
`;

export default H1;
